<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].website.indexImageTitle" class="row hero-content">
					<div class="columns column12">
						<div class="content">
							<h2 class="title">
								{{ defaults[locale].website.indexImageTitle }}
							</h2>
							<div class="hero-divider">
								<img src="~/assets/images/divider.svg" alt="Divider" />
							</div>
							<h3 class="subtitle">
								{{ defaults[locale].website.indexImageSubtitle }}
							</h3>
						</div>
					</div>
				</div>
			</template>
		</heading-image>

		<main-content>
			<template #content>
				<div class="columns column6">
					<h1>{{ page.title || page.header }}</h1>
				</div>
				<div class="columns column6 last-col">
					<div v-parse-links v-html="page.content" />
					<p v-if="page.bookingID">
						<book-button class="button button-cta">
							{{ $t('bookThisRoom') }}
						</book-button>
					</p>
				</div>
			</template>
		</main-content>

		<section class="booking-benefits">
			<div class="row">
				<div class="columns column4 left bg-image-content">
					<h2>{{ defaults[locale].website.benefitsHeader }}</h2>
					<ul>
						<li v-for="(benefit, key) in defaults[locale].website.bookingBenefits" :key="key">
							<span>
								<font-awesome-icon icon="fa-regular fa-check" size="1x" />
							</span>
							<div>{{ benefit.content }}</div>
						</li>
					</ul>
					<book-button class="button button-cta reserve-now">
						{{ $t('bookNow') }}
					</book-button>
				</div>
			</div>
			<picture>
				<source
					v-if="defaults[locale].website.bannerImageWebp"
					:srcset="defaults[locale].website.bannerImageWebp"
					type="image/webp"
				/>
				<source :srcset="defaults[locale].website.bannerImage" />
				<img
					class="bg-image"
					:src="defaults[locale].website.bannerImage"
					:alt="defaults[locale].website.bannerImageAlt"
					loading="lazy"
				/>
			</picture>
		</section>

		<promoblocks :items="promoblocksData" :amount="3">
			<template #header>
				<div v-parse-links class="columns column12" v-html="defaults[locale].website.indexPromotionTitle" />
			</template>
		</promoblocks>

		<contentblocks v-if="page.contentblocks && page.contentblocks.length" :items="page.contentblocks" />

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />
		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].website.newsletterHeader"
			:content="defaults[locale].website.newsletterContent"
			:button="defaults[locale].website.newsletterButton"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.hero-divider {
	width: 100%;
	text-align: center;
	margin: 15px auto;

	img {
		width: 156px;
		height: 30px;
	}
}

.hero-content {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	right: 0;
	z-index: 4;

	.content {
		color: #fff;
		text-align: center;
		max-width: 80%;
		margin: 0 auto;
		animation: fade-in 1500ms ease-out;
		font-family: $heading-font-family;

		p {
			margin: 0;
		}

		.title {
			font-size: 4.6em;
			color: #fff;
			font-weight: 300;
		}

		.subtitle {
			color: #fff;
			font-family: $body-font-family;
			font-size: 20px;
			font-weight: 700;
			letter-spacing: 2px;
			line-height: 100%;
			text-transform: uppercase;
		}
	}
}

.booking-benefits {
	position: relative;
	padding: 200px 0;

	ul {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;
			line-height: 36px;
			vertical-align: middle;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-start;

			div {
				width: calc(100% - 60px);
			}

			span {
				width: 42px;
				height: 44px;
				line-height: 44px;
				border: 2px solid #0083b3;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: center;
				text-align: center;
				margin: 5px 15px 5px 0;

				svg {
					margin: 0;
					font-size: 23px;
					display: inline-block;
					color: #0083b3;
				}
			}
		}
	}
}

.bg-image {
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	z-index: 1;
}

.bg-image-content {
	background: rgba(232 250 250 / 80%);
	backdrop-filter: blur(17px);
	padding: 50px;
	z-index: 3;
}

.intro {
	background: url('~/assets/images/introblock-bg-image.png') repeat-y center right #e8fafa;
	background-size: 700px;

	.column6 {
		padding: 50px 90px 0 20px;

		&.last-col {
			padding: 90px 50px;
			background: #fff;
		}
	}
}

@media (max-width: 900px) {
	.booking-benefits {
		padding: 100px 0;

		ul {
			li {
				position: relative;
				line-height: 26px;
				margin: 8px 0;
			}
		}
	}

	.intro {
		background: url('~/assets/images/introblock-bg-image.png') repeat-y -70% #e8fafa;
		background-size: 700px;

		.column6 {
			padding: 10px 20px;

			&.last-col {
				padding: 30px;
			}
		}
	}
}

@media (max-width: 800px) {
	.hero-image .hero-content .title {
		font-size: 4em;
	}
}

@media (max-width: 600px) {
	.hero-image .hero-content .title {
		font-size: 3em;
	}
}

@media (max-width: 500px) {
	.hero-image .hero-content .subtitle {
		font-size: 14px;
	}

	.hero-image .hero-content .title {
		font-size: 30px;
	}
}
</style>
